.carouselArtworkModalDotList{
    padding:12px;
    position:inherit;
    overflow-x: auto;
    display:flex;
}

.custom-artworkmodal-dot{
    border: 0px;
    background-color: white;
    margin: 0px 8px;
    box-sizing: border-box;
}

.custom-artworkmodal-dot--active{
    padding: 4px;
    border-radius: 12px;
    border: 1px solid #A3A3A3;
}