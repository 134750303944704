.carouselArtistBiographyDotList {
    padding: 12px;
    position: inherit;
    overflow-x: auto;
    display: flex;
}

.custom-artistbio-dot {
    border: 0px;
    background-color: black;
    margin: 0px 8px;
    box-sizing: border-box;
}

.custom-artistbio-dot--active {
    padding: 4px;
    border-radius: 12px;
    border: 1px solid #895e12;
}