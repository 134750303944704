.top-classes-carouselItem{
    padding:12px;
    box-sizing: border-box;
}

.react-multiple-carousel__arrow{
    background: #BD8B40;
}

.react-multiple-carousel__arrow:hover{
    background: #895e12;
}

.react-multiple-carousel__arrow{
    width:42px;
    height: 42px;
}

.react-multiple-carousel__arrow--left{
    left:0
}

.react-multiple-carousel__arrow--right{
    right:0
}