.carousel_arrow{
    background: #BD8B40;
}

.carousel_arrow:hover{
    background: #895e12;
}

@media only screen and (min-width: 960px) {
    .carousel_arrow-right{
        right: calc(4% + 150px)
    }
    
    .carousel_arrow-left{
        left: calc(4% + 150px)
    }
}

.carouselItem{
    padding:12px;
    box-sizing: border-box;
}
