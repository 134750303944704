.carouselArchivedDotList{
    padding:32px;
    position:inherit;
    box-sizing: border-box;
}

.custom-dot{
    background-color: white;
    margin: 0px 10px 0px 10px;
}
